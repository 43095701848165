.imgdiv {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top:-40px;
}

.pdiv {
    padding:20px;
}

.imgsec {
    opacity: 1.0;
}

.imgtxt {
    padding-top:160px;
}

.imgfnt1 {
    font-size: 225%;
    font-weight: bold;
}

ul li {
    padding-bottom:10px;
}

a {
    cursor:pointer;
}


.pull-left {
    float:left;
}

.sub-left-link {
    padding: 0px;
    margin: 0px;
}

.imgcss1 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #339de8 ), url('../../assets/aboutus.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.aboutmenu {
    color:black;
}
.aboutmenu:hover {
    color:orange;
}