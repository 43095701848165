.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.container {
    height: 100%;
    width: 100%;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.menucss {
    font-size: medium;
    font-weight: bold;
    color: orangered;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;
}

.menucss a:hover {
  color:orange;
}

.menucss a.active {
   border-bottom: 3px solid orange;
   color:dodgerblue;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.mainhr {
    color: cornflowerblue;
    transform-style: preserve-3d;
    border-top: 3px solid;
}

.section1 {
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}

    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
        animation-iteration-count: 1;
    }

.box {
    margin: 20px 0;
}

    .box span {
        background-color: rgba(255, 255, 255, 0.5);
        display: inline-block;
        padding: 5px;
        border-radius: 3px;
    }

html {
    box-sizing: border-box;
    font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
}

* {
    box-sizing: inherit;
    font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
}


/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
    .imgtxt {
        left: 10px !important;
    }
    .col-8 {
        max-width: 100% !important;
    }

    .col-6 {
        max-width: 100% !important;
    }

    .col-4 {
        max-width: 100% !important;
    }
    .svcsec1 {
        display: none;
    }
    .careerimg {
        display: none;
    }
}

.imgindu0 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/industries.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu1 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/banking.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu2 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/healthcare.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu3 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/insurance.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu4 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/retail.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu5 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/tele.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu6 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/travel.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgindu7 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/industries/utilities.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv0 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/services.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv1 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/appservice.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv2 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/bpo.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv3 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/cloud.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv4 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/security.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.imgserv5 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('./assets/services/consulting.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}