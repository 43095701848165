.homeimg {
    width:100%;
    height:70%;
}

.leftText {
    position:absolute;
    bottom:50px;
    color:white;
    font-size:xx-large;
    padding-left:10px;
}

.ltp {
    font-size: small;
    align-content:flex-start;
}

.servicessec {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom:80px;
}

.leftText1 {
    position: absolute;
    top: 1180px;
    left: 250px;
    color: white;
    font-size: xx-large;
}

.careersec {
    border: 2px solid;
    align-content: center;
    text-align: center;
    padding-top:110px;
    width:90%;
}

.careersec1 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.careerimg {
    margin-top: -90px;
    margin-bottom: 90px;
    margin-left: -110px;
}

.carreerdiv {
    padding-left:160px;
    padding-right:110px;
}

.svcsec1 {
    margin-left:-20px;
    color:white;
    align-content:center;
    padding-top:180px;
}

.svcsec2 {
    margin-right: -20px;
    margin-left:10px;
    color: white;
    align-content: center;
    padding-top: 180px;
}

.svcbtn {
    color: black;
    border-color: orange;
}

.svcbtn:hover {
     color: black;
     border-color: orange;
     background-color: orange;
}

.svc1btn {
    color: white;
    border-color: white;
}

.svc1btn:hover {
    background-color: white;
    color:orangered;
}

.visionsec1 {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: -90px;
    margin-left: -110px;
    margin-top: 90px;
    z-index:-1
}

.visonsec {
    padding-bottom: 160px;
    padding-top: 160px;
}

.visionbtn {
    color:black;
    border-color:black;
}

.testidiv2 {
    border: 1px solid;
    border-color: white;
    padding-top: 120px;
    padding-left: 180px;
    padding-right: 180px;
    padding-bottom: 120px;
    color: white;
}

.home1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient( rgb(255 0 0 / 0%), #6a83fd );
    color: white;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.homecss {
    position:absolute;
    min-width:100%;
    min-height:100%;
    top:0;
    left:0;
    z-index:-1;
}

.indusimg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient( rgb(255 0 0 / 0%), #287462 );
    color: white;
    position: relative;
    overflow: hidden;
}

.indusimg1 {
    z-index:-1;
}

.txthmsize {
    align-content: center;
    position: relative;
}

.txthmsize .h1 .p {
    font-size: 550%;
}

@media (min-width: 1280px) {
    .htitle {
        font-size: 4.375em;
    }
}

@media (min-width: 970px) {
    .htitle {
        font-size: 3.75em;
    }
}
@media (min-width: 630px) {
    .htitle {
        font-size: 2.5em;
    }
}

.htitle {
    padding-top: 20%;
}
.row {
    display: flex;
    flex-wrap: wrap;
}

/* Create four equal columns */
.col {
    flex: 25%;
    padding: 20px;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
    .col {
        flex: 50%;
    }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .row {
        flex-direction: column;
    }
}