.htxt{
	color:orange;
}

.checkmark {
    position:relative;
    top:3px;
    left:5px;
}

.card1 {
    padding: 10px 10px 10px 10px;
}