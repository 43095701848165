.infotxt {
    width:50%;
    font-size:medium;
}
.imgcss {
    position:relative;
    background: 
        linear-gradient(
            rgba(255, 0, 0, 0.45), 
            rgba(255, 0, 0, 0.45)
        ),
        url('../../assets/clients.jpg')
}

.clisection2 {
    height: 100vh;
}

.clisection3 {
    padding-left: 40px;
    padding-right: 40px;
}

.card-columns {
    column-count:auto;
    padding:10px 10px 10px 10px;
}
