.hdrtxt {
    font-size:225%;
}
.contactsec {
    padding-top:70px;
}

.MuiFormControl-root {
    background-color: white;
    width: 600px;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
    .MuiFormControl-root {
        flex: 50%;
        width: 100%;
    }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .MuiFormControl-root {
        flex-direction: column;
        width: 100%;
    }
}

.btnclr {
    background-color:orange;
    width:100px;
}

.formsec {
    background-color: gainsboro;
    padding-top: 50px;
    padding-bottom: 50px;
}

.addsec {
    background-color:black;
    padding-top:60px;
}

.addsec1 {
    color:white;
}

.imgcss4 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #33e8d785 ), url('../../assets/contact.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}