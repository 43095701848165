.footer {
    bottom: 0;
    width: 100%;
    background-color: black;
    color: white;
    padding:25px;
}

.p {
    font-size:small;
}

ul li {
    list-style-type: none;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
}

a {
    color:white;
    font-size:small;
}

a:hover {
    color:orange;
}

strong {
    color:orange;
}

.bar {
    border-left: 1px solid;
}

