.navbar-brand {
    display: inline-block;
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.form-inline > * {
    margin: 5px 3px;
}

.dropdown .dropbtn {
    border: none;
    outline: none;
    font-family: inherit;
    margin: 0;
}

.dropbtn {
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
}

.dropdown-content a {
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.industriesmenu {
    display: none;
    color: black;
    background-color: aliceblue;
    padding: 20px 20px 20px 20px;
    z-index: 1;
}

.servicesmenu {
    display: none;
    color: black;
    background-color: aliceblue;
    padding: 20px 20px 20px 20px;
    z-index: 1;
}

.submenu {
    color:black;
    font-size:medium;
}

.submenu1 {
    align-content:center;
    padding:10px 10px 10px 10px;
}