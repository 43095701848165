.hc {
   color:orange;
}
.imgtxt {
    position: absolute;
    top: 280px;
    left: 250px;
    color: white;
    font-size: 150%;
}

.imgfnt {
    font-size:225%;
}

.imgcss3 {
    position: relative;
    background: linear-gradient( rgb(255 0 0 / 0%), #9ae83385 ), url('../../assets/careers.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.card2 {
    padding: 20px 20px 20px 20px;
}